#root {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
