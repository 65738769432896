nav {
    background-color: #ffffff;
    margin: 0;
    display: flex;
    align-items: center;
    border-bottom: 2px solid #ded7d7;
    justify-content: space-between;
    position: sticky;
    top: '54px';
    z-index: 1000; /* Lower than head z-index */
}
.nevbar{
	position: sticky;
    top: 54px;
}
nav ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;
}

nav ul li {
	display: inline-block;
	font-size: 17px;
	position: relative; /* Add relative positioning to create underline */
	margin: 0 10px; /* Adjust the value as needed for more or less space */
}

nav .center li:hover {
	background-color: #f0f0f0; /* Change background color on hover */
}

nav .center li:hover::after {
	display: block;
	width: 100%;
	height: 3px;
	background-color: rgb(239, 127, 127);
	position: absolute;
	bottom: 0;
}

nav ul li a {
	text-decoration: none;
	color: #4c4b4b;
}

/* Image */
.icold-logo {
	height: 65px;
	border-radius: 50%;
	margin-right: 10px;
	margin-left: 20px;
	padding-top: 3px;
}

nav .end {
	margin-left: auto; /* Push to the end */
	align-content: center;
}

/* Dropdown container */
.dropdown {
	position: relative;
	cursor: pointer;
}

/* Dropdown menu */
.dropdown ul {
	list-style-type: none;
	padding: 0;
	background-color: #fff; /* Set background color to white */
	position: absolute;
	z-index: 1;
	color: #4c4b4b; /* Set font color to black */
	width: max-content; /* Ensures dropdown width adjusts to content */
	border-radius: 5px; /* Optional: adds rounded corners to dropdown */
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: adds shadow to dropdown */
	display: flex; /* Ensures items are displayed vertically */
	flex-direction: column; /* Display items in a column */
	align-items: flex-start;
	border:1px solid #dee2e6;
}

/* Dropdown items */
.dropdown ul li {
	padding: 5px;
}

.dropdown ul li a {
	text-decoration: none;
	color: #4c4b4b; /* Set anchor text color to black */
}

.dropdown div {
	font-size: 17px;
	color: #4c4b4b;
}

.dropdown ul li:hover {
	background-color: #f0f0f0;
}

.dropdown div:hover {
	background-color: #f0f0f0;
}

.dropdown div:hover::after {
	content: "";
	display: block;
	width: 100%;
	height: 2px;
	background-color: rgb(239, 127, 127);
}

.dropdown div svg {
	vertical-align: middle;
	margin-left: 5px;
	font-size: 14px;
	color: rgb(239, 127, 127);
}

nav ul li.register-button {
	margin-left: 15px;
}

nav ul li.register-button a {
	display: inline-block;
	padding: 12px 23px;
	background-color: #d92445;
	color: #fef9fa;
	text-decoration: none;
	border-radius: 8px;
	border: 1px solid #d92445;
	cursor: pointer;
	font-weight: bold;
	font-size: 19px;
	transition: background-color 0.3s, color 0.3s;
}

nav ul li.login-button a {
	display: inline-block;
	padding: 12px 20px;
	background-color: #ffffff;
	color: #3e4095;
	text-decoration: none;
	border-radius: 8px;
	border: 1px solid #007bff;
	cursor: pointer;
	font-weight: bold;
	font-size: 19px;
	transition: background-color 0.3s, color 0.3s;
}

nav ul li.login-button a:hover {
	background-color: #dc3545;
	border-color: #dc3545;
	color: #fff;
}

nav ul li.register-button a:hover {
	background-color: #3e4095;
	border-color: #dc3545;
	color: #fff;
}
.head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    position: sticky;
    top: 0;
    background-color: white;

		z-index: 1100; /* Increase z-index */
		/* Other styles */
		padding: 0;
    border-bottom: 1px solid rgba(169, 169, 169, 0.5); /* Example border */
}
.line1,
.line2 {
	margin: 0;
}

@media only screen and (max-width: 768px) {
	.head {
		flex-direction: column;
		align-items: stretch;
		position: sticky;
	}

	.start,
	.end,
	.cent {
		width: 100%;
	}

	.end {
		margin-top: 20px;
	}
}

.head .start,
.head .end {
	display: flex;
	align-items: center;
}

.head .start img:first-child {
	width: 150px;
	height: auto;
	margin-right: 20px;
	clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
}

.head .start .image2 {
	width: 175px;
	height: auto;
	margin-right: 20px;
	clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
}

.head .cent {
	flex: 1;
	text-align: center;
}

.head h1 {
	margin: 0;
	font-size: 30px;
}

.head .end p {
	padding: 0 40px;
	margin: 0;
}

.head .end {
	background-color: #d92445;
	clip-path: polygon(4% 0%, 100% 0%, 100% 100%, 0% 100%);
}

.head .end .image3 {
	width: 28px;
	height: auto;
	padding-left: 28px;
	padding-right: 28px;
	background-color: #3e4095;
	padding-top: 5px;
	padding-bottom: 50px;
}

.head .end .line2 {
	color: #ffffff;
	padding: 0 20px;
	margin-right: 10px;
	margin-left: 10px;
}

.head .end .line1 {
	margin: 0;
	color: #ffffff;
	font-size: 20px;
	font-weight: 700;
	background-color: #3e4095;
	clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
	padding-bottom: 5px;
}

.head .end .line2 span {
	vertical-align: middle;
}

.head .end .line2 span:first-child {
	margin-right: 3px;
}

.head .end .line2 span:last-child {
	font-size: 18px;
}

@media (max-width: 1100px) {
	nav {
		flex-direction: column;
		padding: 0 10px;
		width: 100%;
		position: sticky;
	}

	nav ul {
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
	}

	nav ul li {
		margin-right: 0;
		margin-bottom: 10px;
		font-size: 14px;
	}

	.dropdown ul li {
		justify-content: flex-start;
		width: 100%;
		text-align: left;
		padding-right: 50px;
		position: relative;
	}

	.dropdown ul li .icon {
		position: absolute;
		right: 10px;
	}

	.dropdown ul li .box {
		display: none;
	}

	.dropdown ul li:hover .box {
		display: block;
		position: absolute;
		top: 100%;
		right: 0;
		background-color: #fff;
		padding: 5px 10px;
		border: 1px solid #dee2e6;
		
		border-radius: 5px;
		z-index: 2;
	}

	.dropdown div,
	nav ul li.login-button,
	nav ul li.register-button {
		font-size: 14px;
		margin-bottom: 10px;
	}

	.dropdown div:hover::after {
		height: 1px;
	}
}

.start {
	display: flex;
	align-items: center;
}

.center {
	margin: 0 auto; /* Center horizontally */
	width: fit-content; /* Adjust width to fit content */
}

.end ul {
	display: flex;
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.dropdown > div {
	white-space: nowrap;
}

@media screen and (max-width: 768px) {
	.head {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 10px;
	}

	.cent h1 {
		font-size: 24px;
	}

	.end {
		display: none;
	}

	nav {
		display: flex;
		flex-direction: column;
		align-items: center;
		box-shadow: 0 0.5rem 1rem #00000026 !important;
		position: sticky;
	}

	.icold-logo {
		margin-bottom: 0px;
	}

	.center ul {
		padding: 0;
	}

	.center li {
		margin-bottom: 10px;
	}

	.center li.dropdown ul {
		display: none;
		position: relative;
		top: 0;
		left: 0;
		margin-top: 5px;
	}

	.center li.dropdown.show ul {
		display: block;
	}

	.center li.dropdown div {
		cursor: pointer;
	}
}

/* Add this CSS for hamburger icon */
.hamburger-icon {
	display: none; /* Hide the icon by default */
	cursor: pointer;
}

@media screen and (max-width: 768px) {
	/* Show the hamburger icon only on mobile view */
	.hamburger-icon {
		display: block;
	}

	/* Hide the menu by default on mobile view */
	.center ul {
		display: none;
	}

	/* Show the menu when the showMenu state is true */
	.center ul.show-menu {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}

	/* Show submenus */
	.center ul ul {
		display: none;
	}

	/* Show submenus when their parent menu item is clicked */
	.center ul li:hover > ul {
		display: flex;
		flex-direction: column;
		align-items: left;
	}
}

/* External stylesheet or within your React component */
.nested-dropdown-list {
	position: absolute; /* Example positioning */
	top: 10%; /* Example placement below parent dropdown */
	left: 103%; /* Example alignment */
	background-color: white; /* Example background color */
	border: 1px solid #dee2e6; /* Example border */
	z-index: 2000; /* Example z-index to ensure it appears above other content */
	padding: 10px; /* Example padding */
	border-radius: 10px;
	max-width: 100%;
}


@media (min-width: 991px) and (max-width: 1280px) {
	nav ul li,
	.dropdown div {
		font-size: 12px !important;
	}
	nav ul li.login-button a,
	nav ul li.register-button a {
		padding: 4px 14px !important;
		font-size: 14px !important;
	}
}
